@import './layout/base.css';

.body__background {
  background-image: url('../images/background-homepage.jpg');
}

.homepage__video {
  background-color: #333;
  background-attachment: scroll;
  display: none;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  z-index: -1;

  @media screen and (min-width: 48rem) {
    display: block;
  }
}

.homepage__captions {
  bottom: 5rem;
  color: var(--white);
  font-size: 1.5rem;
  left: 0;
  line-height: 4rem;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  text-shadow: 1px 1px .25rem rgba(var(--black-rgb), .75);

  @media screen and (min-width: 36rem) {
    bottom: 7.5rem;
    font-size: 2.25rem;
  }

  @media screen and (min-width: 48rem) {
    bottom: 10rem;
    font-size: 2.75rem;
  }
}

.homepage__caption {
  display: none;
}

.homepage__caption--active {
  animation: slide-in 5s;
  display: block;
  transform: scale(1.25);
}

@keyframes slide-in {
  0% { opacity: 0; transform: scale(1); }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; transform: scale(1.25); }
}
