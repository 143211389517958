:root {
  --white: #ffffff;
  --white-rgb: 255, 255, 255;
  --yellow: #ffde00;
  --orange-light: #f39200;
  --orange-dark: #ea5b0c;
  --magenta: #e6007e;
  --magenta-rgb: 230, 0, 126;
  --black: #393536;
  --black-rgb: 57, 53, 54;

  --max-width-sm: 30rem;
  --max-width-md: 36rem;
  --max-width-lg: 48rem;

  --z-body-background: -1;
  --z-navigation: 3;
  --z-navigation-background: 4;
  --z-navigation-container: 5;
  --z-navigation-cookie-wall: 5;
}
