@font-face {
  font-family: 'avenir';
  src: local('avenir-light'), local('avenir-light'), url('../../fonts/avenir-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

/* headings */
@font-face {
  font-family: 'nexa-bold';
  src: local('nexa-bold'), local('nexa-bold'), url('../../fonts/nexa-bold.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'nexa-bold-italic';
  src: local('nexa-bold-italic'), local('nexa-bold-italic'), url('../../fonts/nexa-bold-italic.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
