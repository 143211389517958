@import '../partials/variables.css';
@import '../partials/fonts.css';
@import './navigation.css';

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  background-color: var(--black);
  color: var(--black);
  font-family: 'avenir', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 16px;
  line-height: 1.5rem;
  margin: 0;
}
body.F7e6EoMJNewvairB6RJ7-active {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'nexa-bold', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

p {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 0;
  padding: 0 0 1rem 0;
}
p:last-of-type {
  padding: 0;
}

a {
  color: var(--magenta);
}

button {
  cursor: pointer;
}

.body__background {
  background: transparent url('../../images/background-default.jpg') center center scroll;
  background-size: cover;
  height: 100vh;
  filter: grayscale(60%);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: var(--z-body-background);
}
.body__content {
  padding: 3.25rem 1rem 0 1rem;
}

.content {
  margin: 0 auto;
  max-width: var(--max-width-sm);

  @media screen and (min-width: 48rem) {
    max-width: var(--max-width-lg);
  }
}

.content__title {
  background-color: rgba(var(--white-rgb), .75);
  display: inline-block;
  font-size: 2rem;
  line-height: 100%;
  margin: 2rem 0 0;
  padding: 1rem;

  @media screen and (min-width: 48rem) {
    font-size: 3rem;
  }

}
.content__description {
  background-color: rgba(var(--white-rgb), .75);
  margin: 0 0 2rem;
  padding: 1rem;
}
.content__description p {
  margin: 0 0 1rem;
}
