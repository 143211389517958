.navigation {
  padding: 0 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-navigation);
}
.navigation__container {
  background-color: rgba(var(--black-rgb), .875);
  box-shadow: 0 0 .5rem rgba(var(--black-rgb), .25);
  display: flex;
  margin: 0 auto;
  max-width: 30rem;
  position: relative;
  width: 100%;
  z-index: var(--z-navigation-container);
}
@media screen and (min-width: 48rem) {
  .navigation__container {
    max-width: var(--max-width-lg);
  }
}

.navigation__item {
  border-top: .125rem solid transparent;
  border-bottom: .125rem solid transparent;
  color: var(--white);
  line-height: 3rem;
  padding: 0 1rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .375s ease;
}
@media screen and (max-width: calc(48rem - 1px)) {
  .navigation__items .navigation__item {
    color: var(--black);
  }
}
.navigation__item--active,
.navigation__item:hover {
  border-bottom-color: var(--magenta);
  color: var(--magenta);
}
.navigation__item--logo {
  border: 0;
  margin-right: auto;
  max-height: 3.25rem;
  overflow: hidden;
  position: relative;
}
.navigation__item-logo-circle {
  background-color: rgba(var(--black-rgb), .75);
  box-shadow: 0 0 .5rem rgba(var(--black-rgb), .5);
  display: block;
  border-radius: 50%;
  height: 8.5rem;
  left: 2.65rem;
  position: absolute;
  width: 8.5rem;
  top: -2.6rem;
}
.navigation__item--logo svg {
  height: 3rem;
  position: relative;
  width: 12rem;
}
.navigation__item--toggle {
  background-color: transparent;
  border: 0;
  font-size: 2rem;
  line-height: 2rem;
  padding: 0 1rem .5rem;
}
@media screen and (min-width: 48rem) {
  .navigation__item--toggle {
    display: none;
  }
}

.navigation__items {
  display: none;
}
@media screen and (max-width: calc(48rem - 1px)) {
  .navigation__items {
    background-color: rgba(var(--white-rgb), .875);
    flex-direction: column;
    position: absolute;
    top: 3.375rem;
    width: 100%;
  }
}
@media screen and (min-width: 48rem) {
  .navigation__items {
    display: flex;
  }
}
.navigation--active .navigation__items {
  display: flex;
}

.navigation--active .navigation__background {
  background-color: rgba(var(--black-rgb), .5);
  content: ' ';
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: var(--z-navigation-background);
  transition: background-color .5s ease;

  @media screen and (min-width: 48rem) {
    display: none;
  }
}
